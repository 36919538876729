/*------------------------------------*\
  #TOGGLE
\*------------------------------------*/
.i-toggle {
  display: flex;
  margin: 8px 0;

  &.full-width {
    justify-content: space-between;

    span, label {
      margin-right: 0;
    }
  }

  .label-area {
    display: flex;
    flex-direction: column;
  }

  input[type=checkbox]{
    border: 0; 
    clip: rect(0 0 0 0); 
    clip-path: inset(50%); 
    height: 1px; 
    margin: -1px; 
    overflow: hidden;
    padding: 0; 
    position: absolute; 
    width: 1px; 
  }

  span, label {
    margin-right: 1rem;
    cursor: pointer;
    &:hover {
      color: theme-color('night-violet-lighter-200');
    }
    &.supporting-message {
      color: theme-color('gray-200');
      font-size: 0.75rem;
    }
    &.disabled {
      cursor: default;
      color: theme-color('gray-300');
    }
  }

  label {
    font-size: 1rem;
    line-height: 1.5rem;
    position: relative;
    background-color: transparent;
    padding: 0;
    top: unset;
    left: unset;
    
    &.small {
      font-size: .875rem;
      line-height: 1.25rem;
    }
  }
  
  .toggle {
    cursor: pointer;
    text-indent: -9999px;
    top: 0;
    left: 0;
    min-width: 42px;
    height: 24px;
    background: theme-color('gray-300');
    display: flex;
    border-radius: 100px;
    position: relative;
    transition: 0.3s;
  }

  .toggle:focus,
  input:focus + label .toggle,
  &:focus > .toggle {
    outline: 8px solid theme-color('almost-white');
  }
  
  .toggle:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input:checked + label .toggle {
    background: theme-color('night-violet-lighter-200');
    transition: 0.3s;
  }
  
  input:checked + label .toggle:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  .toggle:active:after {
    width: 24px;
  }

  input:disabled + label .toggle {
    cursor: default;
    background: theme-color('gray-400');
    transition: 0.3s;
  }

  input:disabled + label .toggle:after {
    width: 16px !important;
  }
}
