.file-upload {
  max-width: 540px;
  border: 1px dashed $gray-200;
  border-radius: 20px;
  padding: 1rem;
  background-color: $almost-white-lighter-300;
  color: $gray-200;

  &-name {
    padding-top: 1px;
    color: $night-violet;
    overflow-wrap: anywhere;
  }

  &-link,
  .btn,
  .btn-link {
    text-decoration: underline;
    padding: 0;
  }

  &-accepted-types {
    margin-top: -10px;
    color: $gray-300;
  }

  &-error {
    color: $error;
  }

  &-add-file {
    label {
      position: relative;
      cursor: pointer;
      background-color: inherit;
      padding: auto;
    }
  }
}
