/*------------------------------------*\
  #LISTS
\*------------------------------------*/


ul, ol {
  padding-left: $list-padding-left;

  li {
    margin-bottom: $list-item-margin-bottom;
  }
}
