/*------------------------------------*\
  #BORDERS
\*------------------------------------*/


.rounded-xl {
  border-radius: $border-radius-xl;

  &-top {
    border-top-right-radius: $border-radius-xl;
    border-top-left-radius: $border-radius-xl;
  }

  &-bottom {
    border-bottom-right-radius: $border-radius-xl;
    border-bottom-left-radius: $border-radius-xl;
  }
}

.rounded-md {
  border-radius: $border-radius-md;

  &-left {
    border-top-left-radius: $border-radius-md;
    border-bottom-left-radius: $border-radius-md;
  }

  &-top {
    border-top-left-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;
  }

  &-bottom {
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
  }
}

.rounded-lg {
  border-radius: $border-radius-lg;
  &-lg {
    @include media-breakpoint-up(lg) {
      border-radius: $border-radius-lg !important;
      &.rounded-left-only {
        border-top-right-radius: 0 0 !important;
        border-bottom-right-radius: 0 0 !important;
      }
      &.rounded-right-only {
        border-top-left-radius: 0 0 !important;
        border-bottom-left-radius: 0 0 !important;
      }   
    }
  }  
  &-md {
    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg !important;
      &.rounded-left-only {
        border-top-right-radius: 0 0 !important;
        border-bottom-right-radius: 0 0 !important;
      }
      &.rounded-right-only {
        border-top-left-radius: 0 0 !important;
        border-bottom-left-radius: 0 0 !important;
      }   
    }
  }     
}

.rounded {
  border-radius: $border-radius;
}

.rounded-sm {
  border-radius: $border-radius-sm;
}

.border-xl {
  border-width: $border-width-xl !important;
}

.border-md {
  border-width: $border-width-md !important;
}

.plum-contrast-border {
  border-color: theme-color("plum-purple-aa-contrast") !important;
}

.rounded-none {
  border-radius: 0;
}

.border-white {
  border-color: white;
}