/*------------------------------------*\
  #INPUT
\*------------------------------------*/
.i-textarea {
  @include inputs-core;
  position: relative;
  font-weight: 400;
  textarea {
    height: 46px;
    width: 100%;
    resize:none;
    background-color: transparent;
    padding: 0.875rem 1rem;
    &, &:focus, &:active {
      border: 0;
      outline: 0;
    }
  }
  [class$='control'] {
    height: auto;
    min-height: 48px;
  }
  &.is-invalid &-icon svg {
    stroke: theme-color('error');
    border-color: theme-color('gray-400');
  }
  &.is-disabled &-icon svg,
  span,
  label {
    stroke: theme-color('gray-400');
  }
  &.is-disabled {
    &.has-selection,
    span,
    label {
      border-color: theme-color('gray-400');
      color: theme-color('gray-400');
      font-weight: 400;
    }
    label {
      color: theme-color('gray-300');
    }
  }
  &.has-selection {
    [class$='control'] {
      border-color: theme-color('night-violet-lighter-200');
    }
    &.is-disabled [class$='control'] {
      border-color: theme-color('gray-400');
    }
  }
  &.is-invalid {
    [class$='control'] {
      border-color: theme-color('error');
      box-shadow: 0 0 0 1px theme-color('error');
    }
    input,
    label,
    .supporting-text {
      color: theme-color('error');
    }
  }
  &.has-tooltip &-icon {
    cursor: pointer;
    svg:hover, svg:active, svg:focus {
      stroke: theme-color('night-violet');
    }
  }

  &-icon {
    position: absolute;
    right: 16px;
    transform: translateY(-50%);
    top: 50%;
  }
  input {
    display: flex;
    min-width: 120px;
    width: inherit;
    border: none;
  }

  span,
  label {
    color: theme-color('gray-200');
    font-weight: 400;
  }

  .placeholder {
    color: theme-color('gray-200');
    font-size: 1rem;
  }
  .input-group-text {
    text-align: inherit;
  }
}
