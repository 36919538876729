/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/
.btn {
  padding: $btn-padding-x $btn-padding-y;
  border-radius: $btn-border-radius;

  &:disabled,
  &.disabled,
  &:disabled:hover,
  &.disabled:hover {
    background-color: theme-color('gray-400');
    color: theme-color('gray-300');
    border-color: transparent;
  }
}

.btn-primary {
  color: theme-color("pale-white");
  background: theme-color("primary");
  border-color: theme-color("primary");

  &:hover,
  &.hover {
    color: theme-color("pale-white");
    background: theme-color("night-violet-lighter-200");
    border-color: theme-color("night-violet-lighter-200");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("pale-white");
    background: theme-color("night-violet-lighter-400");
    border-color: theme-color("night-violet-lighter-400");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("pale-white");
    background: theme-color("night-violet-lighter-200");
    border-color: theme-color("night-violet-lighter-200");
  }
}

.btn-secondary {
  color: theme-color("night-violet");
  background: theme-color("plum-purple");
  border-color: theme-color("plum-purple");

  &:hover,
  &.hover {
    color: theme-color("night-violet");
    background: theme-color("plum-purple-lighter-100");
    border-color: theme-color("plum-purple-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("night-violet");
    background: theme-color("plum-purple-darker-100");
    border-color: theme-color("plum-purple-darker-100");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("night-violet");
    background: theme-color("plum-purple-lighter-100");
    border-color: theme-color("plum-purple-lighter-100");
  }
}

.btn-light {
  color: theme-color("night-violet");
  background: theme-color("almost-white");
  border-color: theme-color("almost-white");

  &:hover,
  &.hover {
    background: theme-color("almost-white-lighter-200");
    border-color: theme-color("almost-white-lighter-200");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background: theme-color("almost-white-darker-100");
    border-color: theme-color("almost-white-darker-100");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    background: theme-color("almost-white-lighter-200");
    border-color: theme-color("almost-white-lighter-200");
  }
}

.btn-outline-primary {
  color: theme-color("night-violet-lighter-200");
  background: transparent;
  border-color: theme-color("night-violet-lighter-200");

  &:hover,
  &.hover {
    color: theme-color("night-violet-lighter-200");
    background: theme-color("light-lavender-lighter-300");
    border-color: theme-color("neutral-4");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("night-violet-lighter-400");
    background: theme-color("light-lavender-lighter-400");
    border-color: theme-color("night-violet-lighter-400");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("night-violet-lighter-200");
    background: theme-color("light-lavender-lighter-300");
    border-color: theme-color("night-violet-lighter-200");
  }

  &:disabled,
  &.disabled {
    color: theme-color("gray-300");

    &,
    &:hover {
      border-color: theme-color("gray-300");
      background: theme-color("pale-white");
    }
  }
}

.btn-clear-primary {
  color: theme-color("night-violet-lighter-200");
  background: transparent;
  border-color: transparent;

  &:hover,
  &.hover {
    color: theme-color("night-violet-lighter-200");
    background: theme-color("light-lavender-lighter-300");
    border-color: theme-color("light-lavender-lighter-300");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("night-violet-lighter-400");
    background: theme-color("light-lavender-lighter-400");
    border-color: theme-color("light-lavender-lighter-400");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("night-violet-lighter-200");
    background: theme-color("light-lavender-lighter-300");
    border-color: theme-color("light-lavender-lighter-300");
  }

  &:disabled,
  &.disabled {
    color: theme-color("gray-300");

    &,
    &:hover {
      border-color: theme-color("pale-white");
      background: theme-color("pale-white");
    }
  }
}

.btn-success {
  color: theme-color("pale-white");
  background: theme-color("success");
  border-color: theme-color("success");

  &:hover,
  &.hover {
    color: theme-color("pale-white");
    background: theme-color("success-lighter-100");
    border-color: theme-color("success-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("pale-white");
    background: theme-color("success-darker-200");
    border-color: theme-color("success-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("pale-white");
    background: theme-color("success-lighter-100");
    border-color: theme-color("success-lighter-100");
  }
}

.btn-outline-success {
  color: theme-color("success-darker-100");
  background: theme-color("pale-white");
  border-color: theme-color("success");

  &:hover,
  &.hover {
    color: theme-color("success-darker-100");
    background: theme-color("success-lighter-200");
    border-color: theme-color("success-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("success-darker-200");
    background: theme-color("success-lighter-200");
    border-color: theme-color("success-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("success-darker-100");
    background: theme-color("success-lighter-200");
    border-color: theme-color("success-lighter-100");
  }
}

.btn-clear-success {
  color: theme-color("success-darker-100");
  background: theme-color("pale-white");
  border-color: theme-color("pale-white");

  &:hover,
  &.hover {
    color: theme-color("success-darker-100");
    background: theme-color("success-lighter-200");
    border-color: theme-color("success-lighter-200");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("success-darker-200");
    background: theme-color("success-lighter-200");
    border-color: theme-color("success-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("success-darker-100");
    background: theme-color("success-lighter-200");
    border-color: theme-color("success-lighter-200");
  }

  &:disabled,
  &.disabled {

    &,
    &:hover {
      border-color: theme-color("pale-white");
      background: theme-color("pale-white");
    }
  }
}

.btn-error {
  color: theme-color("pale-white");
  background: theme-color("error");
  border-color: theme-color("error");

  &:hover,
  &.hover {
    color: theme-color("pale-white");
    background: theme-color("error-lighter-100");
    border-color: theme-color("error-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("pale-white");
    background: theme-color("error-darker-200");
    border-color: theme-color("error-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("pale-white");
    background: theme-color("error-lighter-100");
    border-color: theme-color("error-lighter-100");
  }
}

.btn-outline-error {
  color: theme-color("error-darker-100");
  background: theme-color("pale-white");
  border-color: theme-color("error");

  &:hover,
  &.hover {
    color: theme-color("error-darker-100");
    background: theme-color("error-lighter-200");
    border-color: theme-color("error-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("error-darker-100");
    background: theme-color("error-lighter-200");
    border-color: theme-color("error-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("error-darker-100");
    background: theme-color("error-lighter-200");
    border-color: theme-color("error-lighter-100");
  }
}

.btn-clear-error {
  color: theme-color("error-darker-100");
  background: theme-color("pale-white");
  border-color: theme-color("pale-white");

  &:hover,
  &.hover {
    color: theme-color("error-darker-100");
    background: theme-color("error-lighter-200");
    border-color: theme-color("error-lighter-200");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("error-darker-100");
    background: theme-color("error-lighter-200");
    border-color: theme-color("error-darker-100");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("error-darker-100");
    background: theme-color("error-lighter-200");
    border-color: theme-color("error-darker-200");
  }

  &:disabled,
  &.disabled {

    &,
    &:hover {
      border-color: theme-color("pale-white");
      background: theme-color("pale-white");
    }
  }
}

.btn-warning {
  color: theme-color("pale-white");
  background: theme-color("warning");
  border-color: theme-color("warning");

  &:hover,
  &.hover {
    color: theme-color("pale-white");
    background: theme-color("warning-lighter-100");
    border-color: theme-color("warning-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("pale-white");
    background: theme-color("warning-darker-200");
    border-color: theme-color("warning-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("pale-white");
    background: theme-color("warning-lighter-100");
    border-color: theme-color("warning-lighter-100");
  }
}

.btn-outline-warning {
  color: theme-color("warning-darker-100");
  background: theme-color("pale-white");
  border-color: theme-color("warning");

  &:hover,
  &.hover {
    color: theme-color("warning-darker-100");
    background: theme-color("warning-lighter-200");
    border-color: theme-color("warning-lighter-100");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("warning-darker-100");
    background: theme-color("warning-lighter-200");
    border-color: theme-color("warning-darker-200");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("warning-darker-100");
    background: theme-color("warning-lighter-200");
    border-color: theme-color("warning-lighter-100");
  }
}

.btn-clear-warning {
  color: theme-color("warning-darker-100");
  background: theme-color("pale-white");
  border-color: theme-color("pale-white");

  &:hover,
  &.hover {
    color: theme-color("warning-darker-100");
    background: theme-color("warning-lighter-200");
    border-color: theme-color("warning-lighter-200");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: theme-color("warning-darker-100");
    background: theme-color("warning-lighter-200");
    border-color: theme-color("warning-darker-100");
  }

  &:focus,
  &:focus:hover,
  &.focus {
    color: theme-color("warning-darker-100");
    background: theme-color("warning-lighter-200");
    border-color: theme-color("warning-darker-200");
  }

  &:disabled,
  &.disabled {

    &,
    &:hover {
      border-color: theme-color("pale-white");
      background: theme-color("pale-white");
    }
  }
}

.btn-text {
  text-underline-offset: $link-underline-offset;
  color: theme-color("electric-purple");

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &:focus:hover {
    color: theme-color("electric-purple");
    text-decoration: none;

    span.text {
      text-decoration: underline;
    }
  }
}

.btn-plain {
  text-underline-offset: $link-underline-offset;
  color: theme-color("body-color");
  font-weight: $font-weight-normal;

  &,
  &-nav {
    padding: $btn-padding-y-sm $btn-padding-x;
  }

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &:focus:hover {
    color: theme-color("electric-purple");
    text-decoration: none;

    span.text {
      text-decoration: underline;
    }
  }

  &-nav {
    font-size: $font-size-medium;

    &:hover,
    &:focus {
      background: theme-color("almost-white");
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base;
    }
  }
}

.btn-sm {
  font-size: $btn-font-size-sm;
  line-height: $btn-line-height-sm;
  font-weight: $btn-font-weight;
  padding: $btn-padding-y-sm $btn-padding-x;
}

.btn-link {
  color: theme-color("primary");
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    color: theme-color("$plum-purple-aa-contrast");
  }
}