/*------------------------------------*\
  #SHADOWS
\*------------------------------------*/


.shadow-none {
  &.shadow-lg {
    @include media-breakpoint-up(lg) {
      box-shadow: $box-shadow !important;
    }
  }

  &.shadow-md {
    @include media-breakpoint-up(md) {
      box-shadow: $box-shadow !important;
    }
  }
}