/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/

.scroll-content {
  border-radius: $border-radius;
  background-color: theme-color('almost-white-lighter-300');
  padding: 24px 4px 0 24px;
  border: 1px solid theme-color('gray-500');
  position: relative;

  &-area {
    overflow-y: scroll;
    height: 200px;
    padding-right: 12px;
  
    /* Handle */
    &::-webkit-scrollbar {
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: theme-color('gray-400');
      border-radius: 10px;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, rgba(251, 251, 249, 0.00) 0%, #FBFBF9 100%);
      position: absolute;
      height: 40px;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
    }
  }
}



.rounded-figure {
  height: 25px;
  width: 25px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  padding: 5px;

  &--lg {
    padding: 8px;
    height: 32px;
    width: 32px;
  }

  &--xl {
    padding: 9px;
    height: 34px;
    width: 34px;
  }

  .feather {
    max-width: 15px;
    max-height: 15px;
    margin: auto;
    display: block;
  }
}

.feather {
  &--all-pointer-events {
    pointer-events: all;
  }

  &--right {
    position: absolute;
    right: .875rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.contact-block,
.calculator-block {
  min-height: 58px;
}
