/* required bootstrap config */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

/* config */
//@import "./main/modules/mixins";
@import "./main/modules/custom";

/* bootstrap partials */
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
/* rebrand specific styles */
@import "./main/modules/fonts";
@import "./main/modules/type";
@import "./main/partials/borders";
@import "./main/partials/shadows";
@import "./main/partials/layout";
@import "./main/partials/alert";
@import "./main/partials/animations";
@import "./main/modules/background";
@import "./main/partials/collapse";
@import "./main/partials/buttons";
@import "./main/partials/nav";
@import "./main/partials/modal";
@import "./main/partials/forms";
@import "./main/partials/lists";
@import "./main/partials/tables";
@import "./main/partials/tooltips";
@import "./main/partials/loader";
@import "./main/partials/dropdowns";
@import "./main/partials/inputs";
@import "./main/partials/input";
@import "./main/partials/select";
@import "./main/partials/footer";
@import "./main/partials/checkbox";
@import "./main/partials/slider";
@import "./main/partials/radio";
@import "./main/partials/fileupload";
@import "./main/partials/opacity";
@import "./main/partials/clipboard-copy";
@import "./main/partials/grids";
@import "./main/partials/custom-toggle";
@import "./main/partials/toggle";
@import "./main/partials/input-group";
@import "./main/partials/typeahead";
@import "./main/partials/textarea";
@import "./main/partials/card";
@import "./main/partials/skeleton";
@import "./main/partials/status";
@import "./main/partials/badge";
/* utility classes */
@import "./main/partials/utilities"
