/*------------------------------------*\
  #MODAL
\*------------------------------------*/


@include media-breakpoint-down(md) {
  .modal-dialog {
    .modal-content {
      height: 100%;
      box-shadow: none;
      border: none;
      border-radius: 0;
    }
  }
}

.modal {
  @include media-breakpoint-up(lg) {
    padding: 0px 15px;
  }

  .modal-dialog {
    margin: auto;

    .modal-content {
      padding: 24px;
      border-radius: 20px;
      margin: auto;
      @include media-breakpoint-up(lg) {
        padding: 30px 24px;
      }

      .modal-body {
        max-height: 100vh;
        overflow-y: auto;
        padding: 0;

        ul:not(.nav-tabs) {
          padding-left: 24px;
        }
      }

      .modal-header {
        @include media-breakpoint-down(lg) {
          padding: 0 20px 0 0;
        }

        .modal-title {
          width: 100%;
          padding: 0 10px 30px 0;
          @include media-breakpoint-up(lg) {
            padding: 0 20px 30px 0;
          }
          h4 {
            line-height: 1.75rem;
          }
        }

        button.close {
          padding-right: 0;
          position: absolute;
          right: 8%;
        }
      }

      .modal-footer {
        justify-content: center;
        margin-top: 30px;
        padding: 0;

        button.btn {
          width: 350px;
        }
      }
    }
  }

  ~.modal-backdrop {
    background-color: rgba(theme-color('primary'), .8);
    backdrop-filter: blur(15px);
    width: 100%;
    height: 100%;
  }

  &.alternative-backdrop ~ .modal-backdrop {
    background-color: theme-color('black');
    opacity: .5;
  }

  &.opaque-backdrop ~ .modal-backdrop {
    opacity: .5;
  }

  &.sticky {
    .modal-dialog {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-down(md) {
      .modal-dialog-scrollable {
        height: auto;
        justify-content: end;
      }
      .modal-dialog {
        align-items: flex-end;
        min-height: 100%;
      }
      .modal-content {
        max-height: 75vh;
        margin: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}
