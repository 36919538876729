/*------------------------------------*\
  #CLIPBOARD COPY
\*------------------------------------*/

.i-clipboard-copy {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  &-label {
    color: theme-color('gray-800');
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
  &-control {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
  }
  &-value {
    color: theme-color('plum-purple-aaa-contrast');
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
  &-icon {
    position: relative;
    svg {
      background: none;
      cursor: pointer;
    }
  }
}
