.i-skeleton {
    span {
      display: block;
      background: $gray-500;
      height: 1.5rem;
      margin-bottom: 0.75rem;
      max-width: 100%;
    }
    &.active span {
      background: linear-gradient(90deg, $gray-500 25%, $gray-400 33%, $gray-500 66%);
      background-size: 400% 100%;
      animation: skeleton-loading 1.5s ease infinite;
    }
}
