/*------------------------------------*\
  #TABLES
\*------------------------------------*/


.table {
  tr {
    th {
      border-top: $table-border-width solid rgba($table-border-color, .15);
      font-weight: 500;

      &:first-child {
        padding-left: 0;
      }
    }

    td {
      border-top: $table-border-width solid rgba($table-border-color, .15);

      &:first-child {
        padding-left: 0;
      }
    }

    &:last-child {
      th {
        border-bottom: $table-border-width solid rgba($table-border-color, .15);
      }
    }

    &:last-child td {
      border-bottom: $table-border-width solid rgba($table-border-color, .15);
    }
  }
}
