/*------------------------------------*\
  #SELECT
\*------------------------------------*/

.i-select {
  @include inputs-core;
  position: relative;

  &.is-invalid &-icon {
    stroke: theme-color('error');
  }

  &.is-disabled &-icon {
    stroke: theme-color('gray-300');
  }

  &.is-disabled {
    &-icon {
      color: theme-color('gray-400');
    }
  }

  &.has-selection {
    &.is-disabled [class$='control'],
    &-icon {
      color: theme-color('gray-400');
    }
  }

  &-icon,
  &-tooltip-icon {
    pointer-events: none;
    position: absolute;
    right: 16px;
    transform: translateY(-50%);
    top: 50%;
  }
  &-tooltip-icon {
    pointer-events: all;
    right: 46px;
    z-index: 300;
  }

  select {
    display: flex;
    color: theme-color('primary');
    min-width: 120px;
    width: inherit;
    border: none;
  }

  .placeholder {
    font-weight: 400;
    font-size: 1rem;
  }
}
