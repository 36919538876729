/*------------------------------------*\
  #FORMS
\*------------------------------------*/


.form-group {
  position: relative;
}

label {
  color: theme-color('tundora');
  position: absolute;
  top: -10px;
  left: 12px;
  transition: all 0.1s ease;
  font-size: .875rem;
  z-index: 300;
  background: $label-bg;
  padding: 0 .25rem;
  font-weight: normal;
}

.custom-control-label {
  font-size: $custom-control-label-font-size;
}

.form-control,
.custom-select {
  padding: $input-padding-top $input-padding-x $input-padding-bottom;
  line-height: $input-line-height;

  &-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
  }

  &:hover {
    border-color: $input-hover-border-color;
  }

  &:disabled, &.disabled {
    background-color: $input-disabled-bg;
    color: $input-disabled-input-color;

    + label {
       color: $input-disabled-label-color;
     }
    &:hover {
      border-color: $input-border-color;
    }
  }

  &.is-invalid{
    &:focus {
      box-shadow: none;
    }
    &:hover {
      border-color: $input-invalid-border-color;
    }
  }
}

.custom-select {
  &:disabled, &.disabled {
    ~ .input-group-append .custom-select__arrow {
      display: none;
    }
  }
}

.form-control {
  &:placeholder-shown {
    + label {
      font-size: $input-font-size;
      font-weight: lighter;
      top: 12px;
    }
  }

  &-sm {
    &:placeholder-shown {
      + label {
        top: 10px;
        font-size: $input-small-font-size;
        font-weight: lighter;
      }
    }

    ~ .input-group-prepend,
    ~ .input-group-append {
      height: $input-height-sm;
    }
  }

  &--minimized-label:placeholder-shown,
  &:focus {
    + label {
      font-size: $label-focused-font-size;
      top: -10px;
      font-weight: normal;
    }
  }

  &:focus {
    + label {
      color: $label-focused-color;
      font-weight: normal;
    }
  }

  + label {
    pointer-events: none;
  }

  &-simple {
    border: none;
    border-bottom: 1px solid theme-color("neutral-3");
    font-size: $font-size-sm;
    font-weight: normal;
    color: theme-color("tundora");
    padding: 0;
    height: auto;
    background: transparent;

    &[readonly] {
      border: none;
      background: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
}

.input-group-prepend,
.input-group-append {
  position: inherit;
  top: 0;
  bottom: 0;
  z-index: 999;
  height: $input-height;

  .input-group-text {
    border: none;
    padding: 1rem;
    font-weight: lighter;

    &--submit {
      cursor: pointer;

      a {
        display: inline-flex;
      }
    }
  }
}

.input-group-append {
  right: 0;
}

.input-group-prepend {
  left: 0;
  pointer-events: none;
  margin-right: -19px;
  margin-left: -7px;

  .input-group-text {
    padding: $input-group-addon-padding;

    &:after {
      content: '';
      position: absolute;
      background-color: $input-group-addon-border-color;
      width: $input-group-addon-border-width;
      height: $input-group-addon-border-height;
      right: 0;
    }

    &--no-border {
      &:after {
        display: none;
      }
    }
  }
}

.invalid-feedback {
  text-align: left;
  font-size: $invalid-feedback-font-size;
  line-height: $invalid-feedback-line-height;
  margin: $invalid-feedback-margin;
}

.info-feedback {
  font-size: $info-message-font-size;
  color: $info-message-color;
  margin: $info-message-margin;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 200;
  position: relative;
  height: $custom-select-height;
  font-weight: lighter;
  padding: 1.075rem 1rem 0.625rem;
  line-height: 1.2;

  &:focus {
    border-color: $custom-select-focus-border-color;
  }

  &.not-selected {
    + label {
      font-size: $input-font-size;
      font-weight: lighter;
      top: 12px;
      pointer-events: none;
    }
  }

  option {
    color: theme-color("neutral-2");
  }
}

.form-control-select {
  position: relative;

  &__chevron {
    z-index: 990;
    pointer-events: none;
    position: absolute;
    right: .75rem;
    top: 1rem;
  }

  &-info {
    &:before {
      content: '';
      position: absolute;
      background-color: $input-group-addon-border-color;
      width: $input-group-addon-border-width;
      height: $input-group-addon-border-height;
      right: 3rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 990;
    }
  }
}

.input-group-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-label {
    position: relative;
    top: 0;
    left: 0;
    color: theme-color("neutral-3");
    background: transparent;
    font-size: .875rem;
    padding: 0;
    transition: all 0.1s ease;
    transform: translateZ(0);

    &__focused {
      color: theme-color("tundora");
    }
  }

  &__input-area {
    display: flex;
    flex-direction: row;
    align-items: center;

    .form-control-input {
      position: relative;

      .form-control {
        border: none;
        border-radius: 0;
        color: theme-color("Tundora");
        font-weight: normal;
        font-size: 1.125rem;
        padding: .3rem 0 .5rem;
        background-color: transparent;
        height: auto;
        min-width: 3ch;
        text-align: right;
        margin-right: .625rem;
      }

      ~ .form-control-select {
        &:after {
          display: none;
        }
        .custom-select {
          width: 1rem;
          min-width: 0;
          margin-left: .5rem;
          padding-left: .5rem;
          padding-right: .5rem;
        }
      }

      &:before {
        content: attr(data-currency);
        position: absolute;
        right: 0;
        top: .15rem;
        font-size: .875rem;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 7px;
        left: 0;
        border-bottom: 1px solid theme-color("royal-purple");
        width: 100%;
        height: 0;
      }

      &--focused {
        &:after {
          bottom: 6px;
          border-width: 2px;
        }
      }

      &--is-invalid {
        &:after {
          border-color: theme-color('danger');
        }
      }

      &--highlight {
        &:before {
          top: .25rem;
        }

        &:after {
          bottom: 4px;
        }

        .form-control {
          font-size: 1.25rem;
          padding: .3rem 0;
        }
      }

      &__currency {
        position: absolute;
        top: 6px;
        font-size: 1.25rem;
        left: 50%;
        transform: translateX(50%);
        color: theme-color("primary");
      }
    }

    .form-control-select {
      position: relative;

      .custom-select {
        height: auto;
        min-width: 8ch;
        position: relative;
        top: 0;
        transform: none;
        line-height: 1;
        padding: .5rem 0 .5rem;
        font-size: 18px;
        border: none;
        background-color: transparent;
        font-weight: normal;
      }

      &__chevron {
        top: 60%;
        transform: translateY(-60%);
        right: 0;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 4px;
        left: 0;
        border-bottom: 1px solid theme-color("royal-purple");
        width: calc(100% - 1.5rem);
        height: 0;
      }

      &.form-control-select--is-invalid {
        &:after {
          border-color: theme-color('danger');
        }
      }

      &--highlight {
        .custom-select {
          padding: .6rem 0;
        }

        &:after {
          bottom: 5px;
        }
      }
    }

    .form-control-input,
    .form-control-select {
      &--disabled {
        &:after {
          display: none;
        }

        .form-control:disabled,
        .custom-select:disabled {
          color: theme-color("tundora");
          font-weight: 400;
          opacity: 1;
        }

        .custom-select:disabled option {
          color: theme-color("tundora");
          text-align: right;
        }

        .form-control-select__chevron {
          display: none;
        }
      }
    }
  }

  .invalid-feedback {
    font-size: $font-size-xsm;
    margin: 0;
    line-height: 1;
  }

  .info-feedback {
    font-size: $font-size-xsm;
    margin: 0;
    color: theme-color("tundora");
    display: none;
    line-height: 1;

    &--visible {
      display: block;
    }
  }

  &--lead {
    padding-bottom: .25rem;

    &:before {
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      border-bottom: 1px solid theme-color("neutral-3");
    }

    .form-label {
      color: theme-color("neutral-2");
      font-weight: $font-weight-thin;
    }
    .form-control-input,
    .form-control-select {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }

      .form-control,
      .custom-select {
        text-align: center;
        font-size: 1.875rem;

        option {
          font-size: 12px;
        }
      }
      .form-control,
      .custom-select {
        color: theme-color("primary");
      }
      .info-feedback {
        color: theme-color("neutral-2");
      }
    }
  }

  &--light {
    .form-label {
      color: theme-color("neutral-4");
      font-weight: $font-weight-thin;
    }
    .form-control-input,
    .form-control-select {
      &:before {
        color: theme-color("primary-light");
      }
      &:after {
        border-color: theme-color("primary-light");
      }

      &--is-invalid {
        &:after {
          border-color: theme-color('danger');
        }
      }
      .form-control,
      .custom-select {
        color: theme-color("primary-light");
      }
    }
    .info-feedback {
      color: theme-color("primary-light");
    }
    .form-control-input__currency {
      color: theme-color("primary-light");
    }
  }

  &--focused {
    &:before {
      border-bottom-width: 2px;
    }
  }

  &--is-invalid {
    &:before {
      border-color: theme-color("danger");
    }
  }
}

.custom-checkbox {
  position: relative;
  padding-left: 1.35rem;

  .custom-control-input {
    &:checked:disabled ~ .custom-control-label::before {
      border-color: theme-color("neutral-3");
    }
  }

  label {
    background-color: transparent;
  }

  &__check {
    position: absolute;
    top: -6px;
    left: 5px;
  }

  &__icon {
    margin-top: -.4rem;
    z-index: 1;
  }

  &.is-invalid {
    .custom-control-label:before {
      border-color: theme-color("danger");
    }
  }
}

.custom-switch {
  padding-left: 1.5rem;

  .custom-control-input {
    ~ .custom-control-label {
      background-color: transparent;

      &:before {
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        background-color: theme-color("neutral-3");
        border: none;
      }

      &:after {
        top: 50%;
        transform: translateY(-50%);
        left: -38px;
        background-color: theme-color("primary-light");
      }

      @include media-breakpoint-down(md) {
        padding-left: 1rem;

        &:before {
          left: -2.2rem;
          height: 24px;
          width: $custom-switch-width-lg;
        }

        &:after {
          left: -34px;
          height: $custom-switch-indicator-size-lg;
          width: $custom-switch-indicator-size-lg;
        }
      }
    }

    &:checked {
      ~ .custom-control-label {
        &:before {
          background-color: theme-color("success");
        }

        &:after {
          transform: translate(.9rem, -50%);
        }
        @include media-breakpoint-down(md) {
          &:after {
            top: 50%;
            transform: translate(1rem, -50%);
          }
        }
      }
    }

    &[disabled] {
      ~ .custom-control-label {
        &:before {
          background-color: theme-color("neutral-5");
        }

        &:after {
          background-color: theme-color("neutral-4");
        }
      }
    }
  }
}

input[type=number] {
  /* Remove controls in firefox */
  -moz-appearance:textfield;

  /* Remove controls in IE */
  &::-ms-clear {
    display: none;
  }

  /* Remove controls in chrome and safari */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes leftover margin */
  }
}

.input-group { // bootstrap default override
  > .form-control {
    &:not(:first-child),
    &:not(:last-child) {
      border-radius: $input-border-radius;
    }
  }
  &:not(.has-validation) {
    > .form-control:not(:last-child) {
      border-radius: $input-border-radius;
    }

    > .form-control-simple:not(:last-child) {
      border-radius: 0;
    }
  }
}

.bank-holder {
  cursor: pointer !important;
  border: 3px solid transparent;
}

.bank-active {
  border-color: theme-color("primary") !important;
}

.hidden {
  visibility: hidden;
}

// add file upload
.file-dropzone-wrapper {
  .file-input {
    display: none !important;
  }

  .file-dropzone {
    background: theme-color('pale-white');
    outline: 1px dashed theme-color('electric-purple');
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    &:hover {
      outline: 2px dashed theme-color('electric-purple');
    }
    &.is-dragover {
      outline: 1px solid theme-color('electric-purple');
      background-color: theme-color('platinum');
      height: 100%;
    }
  }
}
