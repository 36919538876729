/*------------------------------------*\
  #COLLAPSE
\*------------------------------------*/
.collapse-btn {
  &.btn-text {
    color: theme-color('body-color');
    font-weight: $font-weight-normal;
    font-size: $font-size-medium;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:focus, &:focus:hover {
      color: theme-color('body-color');
    }
  }
}
.collapsed .when-open.feather,
.not-collapsed .when-closed.feather {
  display: none;
}

.collapse-header {
  background-color: transparent;

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:focus, &:focus:hover {
    background-color: theme-color('almost-white');
  }
}
.collapse-bg-transparent:hover {
  background-color: transparent !important;
}
