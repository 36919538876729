/*------------------------------------*\
  #TOOLTIPS
\*------------------------------------*/

.tooltip.b-tooltip {
  // bump specificity to override bootstrap styles
  opacity: $tooltip-opacity;

  .tooltip-inner {
    box-shadow: $tooltip-box-shadow;
  }

  &.fade:not(.show) {
    opacity: 0;
  }

  &.show {
    opacity: $tooltip-opacity;
  }

  &.noninteractive {
    pointer-events: none;
  }
}

.i-tooltip {
  position: absolute;
  z-index: 999;
  padding: 0.75rem 1rem;
  border-radius: 0.625rem;
  border: 1px solid theme-color('night-violet');
  .inner {
    width: max-content;
    white-space: normal;
    max-width: 270px;
    font-weight: 400;
  }
  &.primary {
    background: theme-color('night-violet');
    color: theme-color('almost-white-lighter-300');
  }
  &.secondary {
    background: theme-color('plum-purple');
    border-color: theme-color('plum-purple');
    color: theme-color('night-violet');
    .arrow {
      border-top-color: theme-color('plum-purple');
    }
  }
  &.white {
    background: theme-color('pale-white');
    border-color: theme-color('pale-white');
    color: theme-color('night-violet');
    .arrow {
      border-top-color: theme-color('pale-white');
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -9px;
  }
  &[data-popper-placement^='bottom'] > .arrow {
    top: -9px;
  }
  &[data-popper-placement^='left'] > .arrow {
    right: -9px;
  }
  &[data-popper-placement^='right'] > .arrow {
    left: -9px;
  }
  .arrow, .arrow::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    background: inherit;
  }
  .arrow {
    visibility: hidden;
    &:before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  }
  & + svg {
    cursor: pointer;
  }
  .tooltip-fade-enter-active,
  .tooltip-fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .tooltip-fade-enter-from,
  .tooltip-fade-leave-to {
    opacity: 0;
  }
}
