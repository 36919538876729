/*------------------------------------*\
  #NAV
\*------------------------------------*/

.navbar.main {

  &.navbar--scrolled {
    box-shadow:
      rgba(0, 0, 0, 0) 0 0 0 0,
      rgba(0, 0, 0, 0) 0 0 0 0,
      rgba(0, 0, 0, .05) 0 1px 2px 0;
  }

  .navbar-brand {
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
    margin-right: 2.5rem;
  }

  .nav-item {
    padding: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .dropdown {
    .icon-chevron {
      rotate: 0deg;
      transition: rotate .25s;
    }
  }

  .dropdown.show {
    .icon-chevron {
      rotate: 180deg;
      transition: rotate .25s;
    }
  }

  .sub-nav-link {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .nav-item-login {
    padding: 12px 16px;
    line-height: 24px;
    align-self: center;
  }

  .dropdown-item {
    padding: 8px 0;
  }

  .nav-link {
    text-decoration: none;
    color: theme-color('night-violet');
    font-size: 1rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 12px 16px;
    font-weight: 500;

    &:focus-visible {
      outline: none;
      color: initial;
    }

    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &[aria-expanded="true"] {
      color: initial;
      border-radius: 6.25rem;
      background: theme-color("almost-white");
    }
  }

  .sub-nav-link .nav-link {
    font-size: .875rem;
    font-weight: 400;
  }

  .dropdown-menu {
    outline: none;
    padding: 8px;

    .nav-item {
      margin-right: 0;
    }

    a {

      &:hover,
      &:focus {
        border-radius: 12px;
        background-color: theme-color('almost-white-lighter-300');
      }
    }
  }

  .nav-item .dropdown-menu {
    .nav-link {
      padding: 8px 12px;
    }

    .nav-item {
      padding: 0;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .sub-nav-link .dropdown-item {
      font-size: .875rem;
      padding: 8px 12px;
      color: theme-color('primary');
    }

    &--bordered {
      position: relative;
      padding-bottom: 6px;
      margin-bottom: 6px;

      &:after {
        content: '';
        width: calc(100% - 24px);
        height: 1px;
        background-color: rgba(theme-color('primary'), .1);
        position: absolute;
        bottom: 0;
        left: 12px;
      }
    }
  }

  .dropdown-toggle-no-caret::after {
    content: none;
  }

  .logo-main {
    max-width: 182px;
    width: 100%;
    height: auto;
  }

  .navbar-toggler {
    border: 0;

    &:focus {
      outline: 0;
    }
  }

  .username {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  .user-icon {
    top: 0;
    right: 0;
    transform: translate(-70%, 0%);
    padding: 3px;
  }

  .user-menu {
    .dropdown-toggle {
      display: flex;
    }

    .dropdown-menu {
      padding: .5rem
    }

    .dropdown-item,
    .user-group {
      color: theme-color('primary');
      padding: .5rem .75rem;
      line-height: 1.5rem;
      font-weight: 500;
    }

    .user-group .dropdown-item {
      display: flex;
      font-weight: 400;
      align-items: center;

      &:hover,
      &:focus {
        border-radius: 12px;
        background-color: theme-color('almost-white-lighter-300');
      }
    }

    .dropdown-divider {
      margin: .5rem .75rem;
      padding: 0;
    }
  }
}

.nav-pills {
  margin-bottom: 1rem;

  .nav-link {
    display: inline-block;
    font-weight: 400;
    color: theme-color('night-violet');
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid theme-color('night-violet');
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 6.25rem;
    transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    background: transparent;
    padding: .5rem 1.5rem;
    margin-bottom: .5rem;
  }

  .nav-item:not(:last-child) {
    margin-right: .5rem;
  }

  .nav-link.active {
    color: theme-color('pale-white');
    background: theme-color('night-violet');
    border-color: theme-color('night-violet');
  }
}

@include media-breakpoint-down(lg) {
  .navbar-collapse {
    transition-duration: 0ms;
  }

  // TODO: need to see how to make the page under menu not scrollable when menu open
  .navbar.main {
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    align-items: flex-start;

    .logo-main {
      max-width: 120px;
    }

    &.open {
      overflow-y: scroll;
      position: fixed;
      height: 110vh;
    }

    .nav-mobile {
      .nav-link {
        font-size: 1rem;
        font-weight: 500;
        padding: 16px 0;
      }

      .nav-item {
        list-style: none;
        margin-right: 0;
        border-bottom: 1px solid rgba(theme-color('primary'), .1);
        border-radius: 0;

        .dropdown-item {
          padding: 10px 12px;
          border-bottom: 0;
        }
      }

      .third-level {
        font-size: .875rem;
        padding: 10px 12px;
        font-weight: 400;
        color: theme-color('primary');

        .menulink {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 8px;
          }
        }
      }

      .b-nav-dropdown {
        .dropdown-menu {
          position: relative;
          margin-top: 0;
          float: none;
          border: 0;
          border-radius: 0;
          padding: 8px 0 0;
          border-top: 1px solid rgba(theme-color('primary'), .1);

          >a {
            padding: 10px 12px;
          }

          .nav-item {
            margin-bottom: 0;

            a {
              padding: 8px 12px;
            }

            &:last-child {
              margin-bottom: 16px;
            }
          }
        }
      }

      .user-menu {
        margin-top: 8px;
        margin-bottom: 8px;

        .user-group {
          padding: 8px 12px 0 12px;
        }

        .dropdown-item {
          font-size: 16px;
          padding: 8px 12px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar.main {
    .navbar-brand {
      margin-right: 0;
    }

    .logo-main {
      height: 40px;
      width: 113px;
    }

    .nav-item .dropdown-menu {
      padding-right: 12px;
      padding-left: 12px;
      padding-top: 8px;

      .nav-link {
        padding: 0;
      }
    }

    .login-button-mobile {
      padding: 12px 8px !important;
      border: 0;
    }

    .navbar-toggler {
      padding: 12px;
      margin-right: -12px;
    }

    .user-icon {
      top: 0;
      left: 0;
      transform: translate(80%, 65%);
      padding: 3px;
    }
  }
}

.nav-tabs {
  background-color: transparent;
  margin-bottom: .5rem;

  .nav-item {
    margin: 0 1rem 1rem 1rem;
    padding: .5rem .25rem;
  }

  .nav-link {
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding: .125rem .25rem;
    position: relative;
    display: inline-block;
    text-align: center;
    color: theme-color("gray-200");
    border-radius: 4px;

    &:active {
      background-color: transparent;
    }

    &:hover {
      font-weight: 500;
      color: theme-color('primary');
    }

    /* needed to get rid of layout shift on hover when text becomes bold and makes the link little bit wider */
    &:before {
      content: attr(title);
      display: block;
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &.active {
      font-weight: 500;
      background-color: transparent;

      &:focus-visible {
        background-color: theme-color('almost-white');
        border-radius: 4px;
        outline: none;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        border-radius: 10px;
        width: 100%;
        height: 3px;
        background-color: theme-color('primary');
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .navbar-brand {
    margin-right: 0;
  }


  .navbar-toggler.position-relative {
    padding-left: 0;
    padding-right: 0;
  }

  .btn.d-lg-none.btn-plain-nav {
    padding-left: 0;
    padding-right: 0;
  }
}