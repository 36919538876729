/*------------------------------------*\
  #BACKGROUND
\*------------------------------------*/
.bg-image-container {
  padding: 0;
  figure, img {
    border-radius: $border-radius-lg;
  }
}


@include media-breakpoint-down(md) {
  .bg-image-container {
    padding: 0;
    figure, img {
      border-radius: 0;
    }
  }
}

@include media-breakpoint-down(md) {
 .bg-soft-fluffy-purple-md {
   background-color: theme-color('soft-fluffy-purple');
 }
}

.extend-bg {
  position: relative;
  &:before {
    left: -100%;
  }
  &:after {
    right: -100%;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    &-md:before, &-md:after {
      content: none;
    }
  }
  @include media-breakpoint-up(lg) {
    &-lg:before, &-lg:after {
      content: none;
    }
  }    
}

.bg-light-lavender {
  &, &.extend-bg:before, &.extend-bg:after {
    background-color: theme-color('light-lavender');
  }
}

