/*------------------------------------*\
  #RADIO
\*------------------------------------*/

.i-radio {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;

  &-control {
    transition: all 120ms ease-in-out;
    display: flex;
    place-items: center;
    grid-template-columns: 1rem auto;
    gap: 0.7rem;
    position: initial;
    background: transparent;
    padding: 0;
  }

  &.is-disabled {
    color: theme-color('gray-400');
    cursor: not-allowed;
    pointer-events: none;
    input {
      border-color: theme-color('gray-400') !important;
      cursor: not-allowed;
    }
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
    accent-color: theme-color('night-violet-lighter-200');
    border-radius: 50%;
    outline: none;
    transition: 0.1s;
  }

  input[type='radio']:hover:before {
    box-shadow: 0px 0px 0px 7px theme-color('gray-400');
    border: 1px solid theme-color('night-violet-lighter-200');
    background-color: theme-color('gray-400');
    border-radius: 50%;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  input[type='radio']:hover:checked:before {
    background: none;
  }
}

.i-radio-logo {
  padding: 6px;
  border: 0.125rem solid theme-color('gray-300');
  border-radius: 5px;
  width: 95px;
  height: 95px;
  @include media-breakpoint-up(lg) {
    width: 105px;
    height: 105px;
  }

  &-control {
    transition: all 120ms ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    place-items: baseline normal;
    gap: 0.2rem;
    padding: 0;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
  }

  .logo {
    height: 50px;
    width: 50px;  
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include media-breakpoint-up(lg) {
      height: 60px;
      width: 60px;
    }
  }

  &.is-disabled {
    border-color: theme-color('gray-400') !important;
    cursor: not-allowed;
    pointer-events: none;
    background: theme-color('gray-600');

    input {
      border-color: theme-color('gray-400') !important;
      cursor: not-allowed;
    }

    .logo{
      opacity: 0.4;
    }
  }

  input[type='radio'] {
    width: 12px;
    height: 12px;
    accent-color: theme-color('night-violet-lighter-200');
    border-radius: 50%;
    outline: none;
    transition: 0.1s;
  }

  input[type='radio']:hover:before {
    box-shadow: 0px 0px 0px 7px theme-color('gray-400');
    border: 1px solid theme-color('night-violet-lighter-200');
    background-color: theme-color('gray-400');
    border-radius: 50%;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  input[type='radio']:hover:checked:before {
    background: none;
  }

  &:hover {
    border: 2px solid theme-color('night-violet-lighter-300');
  }

  &.is-checked{
    border: 2px solid theme-color('night-violet-lighter-300');
  }

}
