/*------------------------------------*\
  #LOADER
\*------------------------------------*/

.initial-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: theme-color("body-bg");
  z-index: $zindex-loader;
}

@keyframes circleAnim {
  0% {
    fill: #21093A;
  }

  33.33% {
    fill: #DEDEDE;
  }

  66.66% {
    fill: #DEDEDE;
  }

  100% {
    fill: #21093A;
  }
}
