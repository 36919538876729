// stylelint-disable declaration-no-important, selector-list-comma-newline-after
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// Headings

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-weight: normal;
  font-style: normal;
}

h1, .h1 {
  font-family: $font-family-heading-regular;
  font-size: $h1-font-size;
  line-height: $h1-line-height;

  &.italic {
    font-family: $font-family-heading-italic;
    font-style: normal;
  }

  em,
  i {
    font-family: $font-family-heading-italic;
    font-style: normal;
  }
}

h2, .h2 {
  font-family: $font-family-heading-regular;
  font-size: $h2-font-size;
  line-height: $h2-line-height;

  &.italic {
    font-family: $font-family-heading-italic;
    font-style: normal;
  }

  em,
  i {
    font-family: $font-family-heading-italic;
    font-style: normal;
  }
}
h3, .h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}
h4, .h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}
h5, .h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}
p {
  line-height: $p-line-height;
}
a {
  text-underline-offset: $link-underline-offset;

  .arrow {
    transition: margin-left ease 0.1s;
  }

  &:hover,
  &:active,
  &:focus {
    .arrow {
      margin-left: 3px;
    }
  }

  &.dark {
    color: $link-color-dark;

    &:hover,
    &:active,
    &:focus {
      color: $link-hover-color-dark;
    }
  }
}

@include media-breakpoint-down(xl) {
  h1, .h1 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

@include media-breakpoint-down(lg) {
  h1, .h1 {
    font-size: $h1-small-font-size;
    line-height: $h1-small-line-height;
  }
  h2, .h2 {
    font-size: $h2-small-font-size;
    line-height: $h2-small-line-height;
  }
  h3, .h3 {
    font-size: $h3-small-font-size;
    line-height: $h3-small-line-height;
  }
  h4, .h4 {
    font-size: $h4-small-font-size;
    line-height: $h4-small-line-height;
  }
  h5, .h5 {
    font-size: $h5-small-font-size;
    line-height: $h5-small-line-height;
  }
}

// Font weights
.extra-strong, .font-weight-extra-strong {
  font-weight: $font-weight-extra-strong;
}

.font-weight-strong, .bold, strong {
  font-weight: $font-weight-strong;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-normal {
  font-weight: $font-weight-normal;
}

.font-weight-light, .thin, .light {
  font-weight: $font-weight-thin;
}

// Paragraph sizes
.large {
  font-size: $font-size-large;
  line-height: $line-height-large;
}

.medium {
  font-size: $font-size-medium;
  line-height: $line-height-medium;

  &.floating {
    @include media-breakpoint-down(lg) {
      font-size: $font-size-base;
      line-height: $line-height-base;
    }
  }
}

.regular, .normal {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

small,
.small {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

.extra-small {
  font-size: $font-size-xsm;
  line-height: $line-height-xsm;
}

.text-sebenta-regular {
  font-family: $font-family-heading-regular;
}

.text-inter {
  font-family: $font-family-inter;
}

// Other
::selection {
  background: theme-color('gray-500');
}
