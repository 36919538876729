/*------------------------------------*\
  #UTILITY CLASSES
\*------------------------------------*/

.u-slider-container {
  overflow: hidden;
  display: flex;
  .u-slider-wrapper {
    text-align : center;
    display: flex;
    height: auto;
    animation: slide 45s linear infinite;

    div img, div a img {
      max-height: 64px;
      max-width: 180px;
    }

    &:focus, &:hover {
      animation-play-state:paused;
    }
  }
  }
  
  @keyframes slide {
    from{
      transform: translateX(0px);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .u-animation-duration-10 {
    animation-duration: 10s !important;
  }
  
  .u-animation-duration-20 {
    animation-duration: 20s !important;
  }
  
  .u-animation-duration-30 {
    animation-duration: 30s !important;
  }
  
  .u-animation-duration-40 {
    animation-duration: 40s !important;
  }
  
  .u-animation-duration-50 {
    animation-duration: 50s !important;
  }
  
  .u-animation-duration-60 {
    animation-duration: 60s !important;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  .lh-1 {
    line-height: 1 !important;
  }

  .group:hover {
    .group-hover\:underline {
      text-decoration: underline !important;
    }
  }

  .emphasized-links {
    a:hover {
      color: $plum-purple-aa-contrast
    }
  }