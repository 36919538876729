/*------------------------------------*\
  #OPACITY
\*------------------------------------*/


@for $i from 0 through 10 {
  $opacity: $i / 10;
  .opacity-#{$i * 10} {
    opacity: $opacity;
  }
}
