@media (min-width: 992px) {
  .container.container-extra-small {
    max-width: 560px;
  }
}

@media (min-width: 992px) {
  .container.container-small {
    max-width: 792px;
  }
}
@media (min-width: 1280px) {
  .container.container-medium {
    max-width: 1200px;
  }
}
