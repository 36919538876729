/*------------------------------------*\
  #INPUTS
\*------------------------------------*/

$input-border-radius: 8px;
$border-color: theme-color('night-violet-lighter-200');

@mixin inputs-core {
  color: theme-color('gray-200');
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  & [class$='control'] {
    position: relative;
    transition: all 0.3s ease;
    &:hover {
      border-color: theme-color('night-violet-lighter-200');
    }
    &:focus-within {
      border-color: theme-color('night-violet-lighter-200');
      box-shadow: 0 0 0 1px theme-color('night-violet-lighter-200');
    }
    height: 48px;
    align-items: center;
    line-height: normal;
    border: 1px solid theme-color('gray-400');
    border-radius: $input-border-radius;
    display: flex;
    width: 100%;
  }

  &.is-invalid {
    [class$='control'] {
      border-color: theme-color('error');
      box-shadow: 0 0 0 1px theme-color('error');
    }
    select,
    input,
    label,
    .supporting-text,
    textarea {
      color: theme-color('error') !important;
    }
  }

  &.is-disabled {
    [class$='control'] {
      background-color: #fbfaf9;
      border-color: theme-color('gray-400');
    }
    label,
    select,
    input,
    .supporting-text,
    .placeholder,
    textarea {
      color: theme-color('gray-300');
    }
    select:disabled, input:disabled, textarea:disabled {
      -webkit-text-fill-color: theme-color('gray-300');
      -webkit-opacity: 1;
      opacity: 1;
    }
  }

  .supporting-text {
    color: theme-color('gray-200');
    padding: 0 1rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 500;
  }

  label {
    border-radius: 100px;
    font-size: 1rem;
    height: 18px;
    color: theme-color('gray-200');
    position: absolute;
    top: 13px;
    left: 9px;
    z-index: 300;
    padding: 0 0.5rem;
    pointer-events: none;
  }


  &:focus-within,
  &.has-selection {
    label {
      background: theme-color('white');
      font-weight: 400;
      top: -9px;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
  
  &:focus-within label {
    color: theme-color('night-violet-lighter-200');
  }

  select,
  input,
  textarea {
    &:focus,
    &:focus-within {
      outline: none;
      box-shadow: none;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: inherit;
    background-color: transparent;
    z-index: 200;
    height: 100%;
    position: relative;
    box-shadow: none;
  }

  select {
    padding: 0 3rem 0 1rem;
  }

  input {
    padding: 0 1rem;
  }

  &--alternative {
    label {
      background-color: transparent;
      color: theme-color('night-violet-lighter-200');
    }

    &.has-selection label {
      background-color: theme-color('light-lavender');
    }

    &:hover {
      &.has-selection label {
        background-color: theme-color('white');
      }
    }

    &:focus-within label {
      background-color: theme-color('white');
    }

    .i-input-control,
    .i-select-control {
      border-color: theme-color('night-violet-lighter-200');

      &:hover,
      &:focus-within {
        background-color: theme-color('white');
      }
    }
    
    input {
      color: theme-color('primary');
    }
  }
  &.is-v2 {
    label {
      left: unset;
    }
    &:focus-within,
    &.has-selection {
      label {
        left: 0;
      }
    }    
  }
}
