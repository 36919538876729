/*------------------------------------*\
  #ALERTS
\*------------------------------------*/

.alert {
  border-radius: $border-radius;
  padding: 16px;
  color: theme-color('night-violet');
  border: none;
  font-weight: $font-weight-medium;
  font-family: $font-family-inter;

  &-warning {
    background-color: theme-color('warning-light');

    svg.icon {
      fill: theme-color('warning');
      stroke: theme-color('warning-light');
    }
  }

  &-info {
    background-color: theme-color('info-light');

    svg.icon {
      fill: theme-color('info');
      stroke: theme-color('info-light');
    }
  }

  &-danger {
    background-color: theme-color('danger-light');

    svg.icon {
      fill: theme-color('danger');
      stroke: theme-color('danger-light');
    }
  }

  &-success {
    background-color: theme-color('success-light');

    svg.icon {
      fill: theme-color('success');
      stroke: theme-color('success-light');
    }
  }

  &-dismissible {
    .close {
      position: absolute;
      top: 14px;
      right: 14px;
      padding: 0;
      opacity: 1;

      svg.icon {
        fill: theme-color('primary');
      }
    }
  }
}
