.custom-text-toggler {
  label {
    display: block;
    position: static;
    padding: 0;
    font-size: 1rem;
    background: transparent;
  }
}
label.custom-text-toggle {
  max-width:403px;
  flex: 0 0 158px;
  background-color: theme-color('almost-white-lighter-100');
  border: 1px solid theme-color('gray-400');
  padding: 0.25rem;
  border-radius: 6.25rem;
  input:focus ~ & {
    border-color: theme-color('night-violet-lighter-200');
  }
  input ~ & .off,
  input ~ & .on {
    color: transparentize(theme-color('night-violet'), .25);
    border: 1px solid transparent;
    min-width: 75px;
    border-radius: 6.25rem;
    padding: 0.5rem 1.5rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    span {
      line-height: 1;
    }
    svg path {
      fill: transparentize(theme-color('night-violet'), .25);
    }
    &:hover {
      background-color: white;
      color: theme-color('night-violet');
    }
  }
  input:checked ~ & .on,
  input:not(:checked) ~ & .off {
    border: 1px solid theme-color('grey-grey-4');
    background-color: white;
    color: theme-color('night-violet');
  }
  input:checked ~ &.high-emphasis .on,
  input:not(:checked) ~ &.high-emphasis .off {
    background-color: theme-color('night-violet');
    border-color: theme-color('night-violet');
    color: white;
    svg path {
      fill: white;
    }
  }
}
/**
  visually hide the checkbox
  but allow it to be easily reached by screen-readers
*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
