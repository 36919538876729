/*------------------------------------*\
  #SLIDER
\*------------------------------------*/

.i-slider {
  &__custom-labels {
    height: 1.25rem;
    color: theme-color('gray-200');
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  // Active state styling
  .vue-slider {
    &-process,
    &-rail {
      border-radius: 0;
    }
    &-process {
      background: theme-color('night-violet');
    }
    &-mark-label {
      margin-top: 1.563rem !important;
    }
    &-rail {
      width: calc(100% - 52px);
      margin-left: 26px;
      margin-right: 26px;
      position: relative;
      background-color: theme-color('gray-500');

      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 4px;
        width: 25px;
        top: 0;
      }

      &:before {
        border-top-left-radius: 0.313rem;
        border-bottom-left-radius: 0.313rem;
        left: -25px;
        background-color: theme-color('night-violet');
      }

      &:after {
        border-top-right-radius: 0.313rem;
        border-bottom-right-radius: 0.313rem;
        right: -25px;
        background-color: theme-color('gray-500');
      }
    }
    &-dot {
      &-handle {
        background: theme-color('plum-purple');
        border-radius: 6.25rem;
        box-shadow: none;
        transition: 0.3s;
        height: 100%;
        &:after{
          content: url("data:image/svg+xml,%3Csvg width='22' height='12' viewBox='0 0 22 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 6L1 6' stroke='%2321093A'/%3E%3Cpath d='M6 1L1 6L6 11' stroke='%2321093A'/%3E%3Cpath d='M13 6L21 6' stroke='%2321093A'/%3E%3Cpath d='M16 1L21 6L16 11' stroke='%2321093A'/%3E%3C/svg%3E%0A");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        &:hover,
        &-focus {
          box-shadow: 0 0 0 0.5rem rgba(theme-color('plum-purple'), .12);
          transition: 0.3s;
        }
        &:active {
          box-shadow: 0 0 0 0.5rem rgba(theme-color('plum-purple'), .3);
          transition: 0.3s;
        }
      }
      &-tooltip {
        &-top {
          top: -0.875rem;
        }
        &-bottom {
          bottom: -0.875rem;
        }
        &-right {
          right: -0.875rem;
        }
        &-left {
          left: -0.875rem;
        }
        &-text {
          white-space: nowrap;
          color: theme-color('almost-white-lighter-300');
        }
        &-inner {
          padding: 0.75rem 1rem;
          border: 1px solid theme-color('night-violet');
          border-radius: 0.625rem;
          background: theme-color('night-violet');
          text-align: center;
          font-size: 1rem;
          font-weight: 600;
          &:after {
            content: "";
            position: absolute;
          }
          &-top,
          &-bottom,
          &-left,
          &-right {
            &:after {
              border-color: transparent;
              border-style: solid;
              border-width: 0.625rem;
            }
          }
          &-top,
          &-bottom {
            &:after {
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
          &-top {
            &:after {
              top: 100%;
              border-top-color: inherit;
            }
          }
          &-bottom {
            &:after {
              bottom: 100%;
              border-bottom-color: inherit;
            }
          }
          &-right,
          &-left {
            top: 50%;
            transform: translate(0, -50%);
          }
          &-right{
            &:after {
              right: 100%;
              border-right-color: inherit;
            }
          }
          &-left {
            &:after {
              left: 100%;
              border-left-color: inherit;
            }
          }
        }
        &-text {
          max-width: 10rem;
          font-weight: 400;
        }
      }
    }
  }
  // Disabled state styling
  .vue-slider-disabled {
    opacity: 1;
    .vue-slider {
      &-process {
        background: theme-color('gray-300');
      }
      &-rail {
        background: theme-color('gray-400');

        &:before {
          background-color: theme-color('gray-300');
        }
  
        &:after {
          background-color: theme-color('gray-400');
        }
      }
      &-dot-handle {
        &-disabled {
          background: theme-color('gray-400');
          box-shadow: none !important;
        }
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
  &.high-emphasis {
    .vue-slider-rail {
      background-color: transparentize(theme-color('night-violet'), .9);

      &:after {
        background-color: transparentize(theme-color('night-violet'), .9);
      }
    }
  }
}