/*------------------------------------*\
  #STYLE VALUES
\*------------------------------------*/


// COLOR SYSTEM
// Primary colors
$night-violet: #21093A;
$pale-white: #FDFDFD; 

// Secondary colors
$plum-purple: #AA93FF;
$light-lavender: #E3D2FF;
$almost-white: #F0F0EA;
$pale-khaki: #D9D4A7;
$signal-orange: #FF9540;
$sky-blue: #95CDF5;

// Extended colors
$night-violet-lighter-100: #3F245B;
$night-violet-lighter-200: #60378B;
$night-violet-lighter-300: #7F55AA;
$night-violet-lighter-400: #9877BB;

$plum-purple-aaa-contrast: #4F1FFF;
$plum-purple-aa-contrast: #7B57FF;
$plum-purple-darker-200: #8F70FF;
$plum-purple-darker-100: #9F85FF;
$plum-purple-lighter-100: #BBA8FF;
$plum-purple-lighter-200: #CBBDFF;
$plum-purple-lighter-300: #DFD6FF;
$plum-purple-lighter-400: #EBE6FF;

$light-lavender-lighter-100: #E9DBFF;
$light-lavender-lighter-200: #EFE6FF;
$light-lavender-lighter-300: #F5F0FF;
$light-lavender-lighter-400: #FCFAFF;

$almost-white-darker-200: #E4E4D8;
$almost-white-darker-100: #EAEAE1;
$almost-white-lighter-100: #F4F4F0;
$almost-white-lighter-200: #F9F9F6;
$almost-white-lighter-300: #FBFBF9;

$pale-khaki-lighter-100: #DDD9B1;
$pale-khaki-lighter-200: #E2DEBB;
$pale-khaki-lighter-300: #E6E3C6;
$pale-khaki-lighter-400: #EBE8D1;

$signal-orange-lighter-100: #FFA45C;
$signal-orange-lighter-200: #FFB070;
$signal-orange-lighter-300: #FFC18F;
$signal-orange-lighter-400: #FFD2AD;

$sky-blue-lighter-100: #ABD7F7;
$sky-blue-lighter-200: #BEE0F9;
$sky-blue-lighter-300: #D0E9FB;
$sky-blue-lighter-400: #E3F2FC;

// Grays
$gray-100: #535252;
$gray-200: #606060;
$gray-300: #9C9C9C;
$gray-400: #DEDEDE;
$gray-500: #E9E9E9;

// Notification colors
$error: #EB5757;
$error-light: #FFE4E4;
$error-lighter-100: #EF7979;
$error-lighter-200: #FBDDDD;
$error-darker-100: #D13E3E;

$warning: #FFB800;
$warning-light: #FFF4D9;
$warning-lighter-100: #FFC633;
$warning-lighter-200: #FFF1CC;
$warning-darker-100: #E59E00;
$success: #53CBA0;
$success-light: #DBFFEE;
$success-lighter-100: #75D5B3;
$success-lighter-200: #DDF5EC;
$success-darker-100: #3AB186;
$success-darker-200: #20986D;
$info: #2C64CB;
$info-light: #E0EAFD;
$notice: #00B8AE;
$notice-light: #CCF7F5;

$white: #fff;
$black: #000;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: (
  "primary": $night-violet,
  "night-violet": $night-violet,
  "pale-white": $pale-white,
  "plum-purple": $plum-purple,
  "light-lavender": $light-lavender,
  "almost-white": $almost-white,
  "pale-khaki": $pale-khaki,
  "signal-orange": $signal-orange,
  "sky-blue": $sky-blue,
  "night-violet-lighter-100": $night-violet-lighter-100,
  "night-violet-lighter-200": $night-violet-lighter-200,
  "night-violet-lighter-300": $night-violet-lighter-300,
  "night-violet-lighter-400": $night-violet-lighter-400,
  "plum-purple-aaa-contrast": $plum-purple-aaa-contrast,
  "plum-purple-aa-contrast": $plum-purple-aa-contrast,
  "plum-purple-darker-200": $plum-purple-darker-200,
  "plum-purple-darker-100": $plum-purple-darker-100,
  "plum-purple-lighter-100": $plum-purple-lighter-100,
  "plum-purple-lighter-200": $plum-purple-lighter-200,
  "plum-purple-lighter-300": $plum-purple-lighter-300,
  "plum-purple-lighter-400": $plum-purple-lighter-400,
  "light-lavender-lighter-100": $light-lavender-lighter-100,
  "light-lavender-lighter-200": $light-lavender-lighter-200,
  "light-lavender-lighter-300": $light-lavender-lighter-300,
  "light-lavender-lighter-400": $light-lavender-lighter-400,
  "almost-white-darker-200": $almost-white-darker-200,
  "almost-white-darker-100": $almost-white-darker-100,
  "almost-white-lighter-100": $almost-white-lighter-100,
  "almost-white-lighter-200": $almost-white-lighter-200,
  "almost-white-lighter-300": $almost-white-lighter-300,
  "pale-khaki-lighter-100": $pale-khaki-lighter-100,
  "pale-khaki-lighter-200": $pale-khaki-lighter-200,
  "pale-khaki-lighter-300": $pale-khaki-lighter-300,
  "pale-khaki-lighter-400": $pale-khaki-lighter-400,
  "signal-orange-lighter-100": $signal-orange-lighter-100,
  "signal-orange-lighter-200": $signal-orange-lighter-200,
  "signal-orange-lighter-300": $signal-orange-lighter-300,
  "signal-orange-lighter-400": $signal-orange-lighter-400,
  "sky-blue-lighter-100": $sky-blue-lighter-100,
  "sky-blue-lighter-200": $sky-blue-lighter-200,
  "sky-blue-lighter-300": $sky-blue-lighter-300,
  "sky-blue-lighter-400": $sky-blue-lighter-400,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "success": $success,
  "success-light": $success-light,
  "notice": $notice,
  "notice-light": $notice-light,
  "error": $error,
  "danger": $error,
  "danger-light": $error-light,
  "warning": $warning,
  "warning-light": $warning-light,
  "success-lighter-100": $success-lighter-100,
  "success-lighter-200": $success-lighter-200,
  "success-darker-100": $success-darker-100,
  "success-darker-200": $success-darker-200,
  "error-lighter-100": $error-lighter-100,
  "error-lighter-200": $error-lighter-200,
  "error-darker-100": $error-darker-100,
  "warning-lighter-100": $warning-lighter-100,
  "warning-lighter-200": $warning-lighter-200,
  "warning-darker-100": $warning-darker-100,
  "info": $info,
  "info-light": $info-light,
  "white": $white,
  "black": $black,
);

$enable-shadows:                false;
$enable-validation-icons:       false;

$grid-breakpoints: (
  xs: 0,
  sm: 540px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1536px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
  xs: 480px,
  sm: 540px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1536px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 2.5),
  7: ($spacer * 3),
  8: ($spacer * 4),
  9: ($spacer * 4.25),
  10: ($spacer * 4.5),
  11: ($spacer * 4.75),
  12: ($spacer * 5),
  13: ($spacer * 5.25),
  14: ($spacer * 5.5),
  15: ($spacer * 5.75),
  16: ($spacer * 6),
  17: ($spacer * 6.25),
  18: ($spacer * 6.5),
  19: ($spacer * 6.75),
  20: ($spacer * 7),
), $spacers);

$grid-gutter-width: 24px;

// body
$body-bg:     theme-color('almost-white-lighter-300');
$body-color:  theme-color('night-violet');

// fonts
$font-family-heading-regular:   'Sebenta', 'Bressay', serif;
$font-family-heading-italic:    'SebentaItalic', 'BressayItalic', serif;
$font-family-sebenta-regular:   'Sebenta', serif;
$font-family-sebenta-italic:    'SebentaItalic', serif;
$font-family-inter:             'Inter', sans-serif;
$font-family-system:            Arial, sans-serif;

$font-family-base:            $font-family-inter;

$font-size-large:             1.25rem; // .large
$line-height-large:           1.75rem;
$font-size-medium:            1.125rem; // .medium
$line-height-medium:          1.75rem;
$font-size-base:              1rem; // .regular, .normal
$line-height-base:            1.5;
$font-size-sm:                .875rem; // small, .small
$line-height-sm:              1.25rem;
$font-size-xsm:               .75rem; // .extra-small
$line-height-xsm:             1rem;

$font-weight-extra-strong:    700; // not yet used
$font-weight-strong:          600; // not yet used
$font-weight-medium:          500;
$font-weight-normal:          400;
$font-weight-thin:            300; // not yet used

$font-weight-base:            $font-weight-normal;

$h1-font-size:                $font-size-base * 4.25;
$h1-line-height:              4.5rem;
$h2-font-size:                $font-size-base * 3;
$h2-line-height:              3.5rem;
$h3-font-size:                $font-size-base * 2;
$h3-line-height:              2.25rem;
$h4-font-size:                $font-size-base * 1.5;
$h4-line-height:              1.75rem;
$h5-font-size:                $font-size-base * 1.125;
$h5-line-height:              1.5rem;

$h1-small-font-size:          $font-size-base * 2;
$h1-small-line-height:        2.5rem;
$h2-small-font-size:          $font-size-base * 1.75;
$h2-small-line-height:        2rem;
$h3-small-font-size:          $font-size-base * 1.5;
$h3-small-line-height:        1.75rem;
$h4-small-font-size:          $font-size-base * 1.25;
$h4-small-line-height:        1.5rem;
$h5-small-font-size:          $font-size-base * 1;
$h5-small-line-height:        1.5rem;

$p-line-height:               1.5rem;
$lead-font-size:              $font-size-base * 1.25;
$lead-line-height:            $font-size-base * 1.875;

$box-shadow:                  0px 0px 40px 0px rgba(0, 0, 0, 0.04);

$border-radius:               0.5rem;     // 8px
$border-radius-sm:            0.25rem;    // 4px
$border-radius-md:            1rem;       // 16px
$border-radius-lg:            1.25rem;    // 20px
$border-radius-xl:            1.875rem;   // 30px

$border-color-translucent:    rgba(33, 9, 58, .15);

$border-color:                $border-color-translucent;
$border-width:                1px;
$border-width-md:             2px;
$border-width-xl:             5px;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           6.25rem;
$btn-border-radius-lg:        6.25rem;
$btn-border-radius-sm:        1.125rem; // 18px

$btn-padding-x:               0.75rem;
$btn-padding-y:               1.5rem;
$btn-font-weight:             $font-weight-normal;

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            1rem;
$btn-font-size-sm:            0.875rem;
$btn-line-height-sm:          1.5rem;

$btn-disabled-color:          theme-color("pale-white");
$btn-disabled-bg-color:       theme-color("gray-5");
$btn-focus-width:             0;
$btn-focus-box-shadow:        none;
$btn-disabled-opacity:        1;

$spinner-border-width:        2px;

// Links
//
// Style anchor elements.

$link-color:                theme-color('primary');
$link-decoration:           underline;
$link-hover-color:          theme-color('primary');
$link-hover-decoration:     underline;
$link-underline-offset:     1px;
$link-color-dark:           theme-color('primary');
$link-hover-color-dark:     theme-color('primary');

// modal
$modal-backdrop-bg:         theme-color("pale-white");
$modal-backdrop-opacity:    1;
$modal-content-border-width:0;
$modal-content-border-radius: $border-radius-lg;
$modal-backdrop-blur:       30px;
$modal-box-shadow:          $box-shadow;
$modal-header-border-width:         0;
$modal-footer-border-width:         0;

$input-btn-border-width:    1px;

// Tooltips

$tooltip-font-size:                 $font-size-base;
$tooltip-max-width:                 200px;
$tooltip-color:                     $body-color;
$tooltip-bg:                        theme-color("white");
$tooltip-border-radius:             $border-radius / 2;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 .75rem;
$tooltip-padding-x:                 .75rem;
$tooltip-box-shadow:                $box-shadow;
$tooltip-arrow-color:               theme-color("white");


// forms
$form-base-bg:                          theme-color("pale-white");
$label-margin-bottom:                   0;
$label-bg:                              $form-base-bg;

$input-bg:                              $form-base-bg;
$input-color:                           theme-color("tundora");
$input-small-font-size:                 .875rem;
$label-focused-font-size:               $input-small-font-size;
$label-focused-color:                   theme-color("electric-purple");
$input-font-weight:                     lighter;
$input-border-color:                    theme-color("neutral-5");
$input-border-radius:                   $border-radius-sm;
$input-border-width:                    $input-btn-border-width;
$input-height-border:                   $input-border-width * 2;
$input-line-height:                     1;

$input-padding-top:                     1.125rem;
$input-padding-bottom:                  .875rem;
$input-padding-x:                       1rem;
$input-height:                          3rem;
$input-font-size:                       $font-size-base;
$input-height-sm:                       2.5rem;
$input-font-size-sm:                    $font-size-sm;
$input-padding-x-sm:                    1rem;
$input-padding-y-sm:                    .5rem;
$input-border-radius-sm:                $border-radius;
$input-hover-border-color:              theme-color("neutral-3");
$input-focus-border-color:              theme-color("electric-purple");
$input-focus-box-shadow:                none;
$input-focus-color:                     $input-color;
$input-invalid-border-color:            theme-color("danger");
$invalid-feedback-font-size:            $input-small-font-size;
$invalid-feedback-line-height:          1.25rem;
$invalid-feedback-margin:               .375rem 0 0 1rem;
$info-message-color:                    theme-color("electric-purple");
$info-message-font-size:                $input-small-font-size;
$info-message-margin:                   $invalid-feedback-margin;
$input-disabled-bg:                     theme-color("white");
$input-disabled-input-color:            theme-color("neutral-3");
$input-disabled-label-color:            $input-disabled-input-color;
$input-group-addon-padding:             1.125rem .75rem .875rem 1.25rem;
$input-group-addon-color:               theme-color("tundora");
$input-group-addon-bg:                  transparent;
$input-group-addon-border-width:        1px;
$input-group-addon-border-height:       40%;
$input-group-addon-border-color:        theme-color("neutral-5");

$custom-select-height:                  $input-height;
$custom-select-focus-border-color:      $input-focus-border-color;
$custom-select-focus-box-shadow:        none;
$custom-select-background:              none;
$custom-select-color:                   $input-color;


$custom-control-label-font-size:                $font-size-base;
$custom-control-indicator-border-color:         theme-color("neutral-3");

$custom-control-indicator-size:                 1.5rem;
$custom-control-indicator-checked-border-color: theme-color("electric-purple");
$custom-control-indicator-active-border-color:  theme-color("electric-purple");
$custom-control-indicator-focus-border-color:   theme-color("electric-purple");
$custom-control-indicator-focus-box-shadow:     none;
$custom-control-indicator-bg:                   $form-base-bg;
$custom-control-indicator-checked-bg:           transparent;
$custom-control-indicator-disabled-bg:          transparent;
$custom-control-indicator-checked-disabled-bg:  transparent;
$custom-control-indicator-active-bg:            theme-color("neutral-3");
$custom-control-label-disabled-color:           theme-color("neutral-3");

$custom-select-bg:                              $form-base-bg;
$custom-select-border-color:                    theme-color("neutral-5");

$custom-checkbox-indicator-border-radius:       $border-radius / 2;
$custom-checkbox-indicator-icon-checked:        none;

$custom-switch-width:                           2rem;
$custom-switch-width-lg:                        2.5rem;
$custom-switch-indicator-border-radius:         $border-radius-xl;
$custom-switch-indicator-size:                  14px;
$custom-switch-indicator-size-lg:               22px;

// Navs

$nav-link-padding-y:                .5rem;
$nav-link-padding-x:                1rem;
$nav-link-disabled-color:           theme-color("neutral-3");

$nav-tabs-border-width:             0;
$nav-tabs-border-radius:            0;
$nav-tabs-link-active-color:        theme-color("primary");
$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-nav-link-color:           $body-color;
$nav-tabs-link-active-border:       2px solid theme-color("primary");
$nav-tabs-link-hover-border:        2px solid theme-color('neutral-5');
$nav-tabs-nav-link-padding:         0.25rem 0;
$nav-tabs-nav-link-margin:          0 1rem;

// Tables

$table-cell-padding:          .75rem;
$table-border-width:          1px;
$table-border-color:          theme-color('primary');

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-border-width:       0;
$dropdown-box-shadow:         $box-shadow;
$dropdown-bg:                 $almost-white;
$dropdown-padding-y:          1rem;
$dropdown-border-radius:      1.25rem;
$dropdown-divider-bg:         $night-violet;
$dropdown-link-color:         theme-color("tundora");
$dropdown-link-hover-color:   theme-color("primary");
$dropdown-link-hover-bg:      transparent;
$dropdown-link-active-color:  theme-color("primary");
$dropdown-link-active-bg:     transparent;
$dropdown-item-padding-x:     1.25rem;

// Loader

$zindex-loader:               1080;

// Divider

$hr-border-color:             $border-color-translucent;

//Lists

$list-padding-left:           1rem;
$list-item-margin-bottom:     0;
