/*------------------------------------*\
  #CHECKBOX
\*------------------------------------*/

.i-checkbox {
  display: flex;
  color: theme-color('night-violet');
  flex-direction: column;
  &-control {
    transition: all 120ms ease-in-out;
    position: relative;
    top: inherit;
    left: inherit;
    display: flex;
    font-size: inherit;
    place-items: baseline;
    min-height: 1.5rem;
    gap: 0.5rem;
    padding: 0;
    background: inherit;
    color: inherit;
  }
  &.is-disabled {
    color: theme-color('gray-400');
    cursor: not-allowed;
    pointer-events: none;
    input {
      border-color: theme-color('gray-400') !important;
      cursor: not-allowed;
    }
  }
  &.is-invalid {
    color: theme-color('error');
    input {
      border-color: theme-color('error') !important;
    }
  }
  .supporting-text {
    color: theme-color('error');
    padding-left: 1.5rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: theme-color('night-violet-lighter-200');
    min-width: 1.125rem;
    width: 1.125rem;
    height: 1.125rem;
    border: 0.15rem solid theme-color('night-violet-lighter-200');
    border-radius: 0.15rem;
    transform: translateY(-0.075rem);
    display: grid;
    place-content: center;
    &:before {
      transition: 120ms transform ease-in-out;
      content: '';
      transform: scale(0);
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(0);
    }
    &:checked {
      background-color: theme-color('night-violet-lighter-200');
    }
    &:disabled:checked {
        background-color: theme-color('gray-400');
    }
    &:checked:before {
      transform: rotate(45deg) scale(1);
    }
    &:focus {
      outline: 2px solid theme-color('night-violet-lighter-400');
      outline-offset: 2px;
    }
  }
}
