/*------------------------------------*\
  #DROPDOWNS
\*------------------------------------*/
.b-dropdown {
  .dropdown-toggle-no-caret::after {
    // @TODO: We can remove this when we get 'no-caret' to work.
    // This should actually work out of the box with 'no-caret' prop on b-dropdown component.
    content: none;
  }

  .dropdown-toggle {
    color: $night-violet;
    display: flex;
    align-items: center;
  }

  .dropdown-divider {
    opacity: 0.1;
    margin: 0 20px;
  }

  .dropdown-item {
    padding: 0.5rem 20px;
  }

  .username {
    max-width: 200px !important;
  }

  &.language-switcher {
    .dropdown-toggle {
      padding-left: 12px;
      padding-right: 12px;

      &:hover,
      &.hover,
      &:active,
      &.active,
      &:focus,
      &.focus,
      &[aria-expanded="true"] {
        background: theme-color('almost-white');
      }
    }

    .dropdown-menu {
      min-width: 60px;
      padding-left: 8px;
      padding-right: 8px;

      li {
        button {
          padding: 8px 12px;

          &:hover,
          &:focus {
            border-radius: 12px;
            background-color: theme-color('almost-white-lighter-300');
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &.language-switcher {
      .dropdown-toggle {
        padding: 12px 8px;
        border: 0;
      }
    }
  }
}