/*------------------------------------*\
  #FOOTER
\*------------------------------------*/


@include media-breakpoint-down(lg) {
  .footer__link {
    font-size: $font-size-sm;
  }
}

.i-footer-container {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}