/*------------------------------------*\
  #ANIMATIONS
\*------------------------------------*/

.fade-enter-active {
  animation: fade-in .5s;
}
.fade-leave-active {
  animation: fade-in .3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blur-in {
  0% {
    filter: blur(0);
  }
  100% {
    filter: blur(30px);
  }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: theme-color('primary');
  color:  theme-color('primary');
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
  margin-left: auto;
  margin-right: auto;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color:  theme-color('primary');
  color:  theme-color('primary');
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color:  theme-color('primary');
  color:  theme-color('primary');
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color:  theme-color('primary');
  }
  50%,
  100% {
    background-color:  theme-color('pale-white');
  }
}

@keyframes skeleton-loading {
  0%{
    background-position:100% 50%;
  }
  100%{
    background-position:0 50%;
  }
}

.v-lazy-image.animated,
.image-loading {
  opacity: 0;
  transition: opacity 1s;
}
.v-lazy-image-loaded.animated,
.image-loaded {
  opacity: 1;
}
