@font-face {
  font-family: 'Sebenta';
  font-display: swap;
  src: url('/fonts/Sebenta-Regular.woff2') format('woff2'),
  url('/fonts/Sebenta-Regular.woff') format('woff')
}

@font-face {
  font-family: 'SebentaItalic';
  font-display: swap;
  src: url('/fonts/Sebenta-RegularItalic.woff2') format('woff2'),
  url('/fonts/Sebenta-RegularItalic.woff') format('woff')
}

@font-face {
  font-family: 'Bressay';
  font-display: swap;
  src: url('/fonts/Bressay_W_Rg.woff2') format('woff2'),
  url('/fonts/Bressay_W_Rg.woff') format('woff')
}

@font-face {
  font-family: 'BressayItalic';
  font-display: swap;
  src: url('/fonts/Bressay_W_It.woff2') format('woff2'),
  url('/fonts/Bressay_W_It.woff') format('woff')
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/InterVariable.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-stretch: 25% 151%;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/InterVariable-Italic.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-stretch: 25% 151%;
  font-style: italic;
}
