.typeahead {
  position: relative;
  width: 100%;

  .i-input {
    border-radius: $input-border-radius;

    & [class$=control] {
      min-height: 48px;
      height: fit-content;
      background-color: theme-color('white');

      .input-group-append {
        height: inherit;
      }
    }

    label {
      background-color: theme-color('white');
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid theme-color('gray-400');
    z-index: 1000;
    background-color: theme-color('white');
    border-radius: $input-border-radius;
    margin-top: .25rem;
    max-height: 300px;
    overflow-y: scroll;

    .dropdown-item {
      padding: .5rem;
      padding-left: 1rem;
      cursor: pointer;

      &:hover, &.active {
        background-color: theme-color('almost-white-lighter-200');
      }
    }
  }
}
