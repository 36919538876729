.i-badge {
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  &-md,
  &-lg {
    padding: 0.25rem 0.5rem ;
  }
  &-sm {
    padding: 0.25rem;
  }
  &-xs {
    padding: 0 0.25rem;
  }

  &__icon {
    &-dot {
      border-radius: 42%;
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.25rem;
      margin-right: 0.5rem;
    }
  }
}
